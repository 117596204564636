import create from "zustand";
import { persist } from 'zustand/middleware'
import {
    QuestionStates, AssessmentStates, RETAKES_AVAILABLE
} from "./components/constants";

const defaults = {
    template: {},
    questions: [],
    currentQuestion: 0,
    assessmentState: AssessmentStates.LOADING,
    questionState: QuestionStates.WAITING,
    attempt: "",
    questionResults: [],
    count: 1,
    totalTime: 0,
    timeLeft: 0,
    isTimeout: false,
    audioLevel: 0,
    uploadProgress: 0,
    sectionNo: 0
};

const useStore = create(
    persist(
        (set, get) => ({
            ...defaults,
            setTemplate: (template) => set({ template }),
            setQuestions: (questions) => set((state) => {
                const bufferTimeInSeconds = (state.template?.bufferTime || 0) * 60; 
                const questionsTotalTime = questions.reduce(
                    (acc, curr) => acc + curr.allottedTime, 0
                );

                return {
                    questions,
                    totalTime: questionsTotalTime + bufferTimeInSeconds,
                    timeLeft: questionsTotalTime + bufferTimeInSeconds,
                };
            }),
            setCurrentQuestion: (currentQuestion) => set({ currentQuestion }),
            setAssessmentState: (assessmentState) => set({ assessmentState }),
            setQuestionState: (questionState) => set({ questionState }),
            setAttempt: (attempt) => set({ attempt }),
            setQuestionResults: (questionResults) => set({ questionResults }),
            setCount: (count) => set({ count }),
            setTotalTime: (totalTime) => set({ totalTime }),
            setTimeLeft: (timeLeft) => set({ timeLeft }),
            setIsTimeout: (isTimeout) => set({ isTimeout }),
            setAudioLevel: (audioLevel) => set({ audioLevel }),
            setSectionDialog: () => set((state) => {
                const questionsLeft = state.questions.slice(
                    state.currentQuestion + 1
                );

                let count = 1;
                if (questionsLeft.length) {
                    const type = questionsLeft[0]?.type;

                    count = questionsLeft.findIndex((q) => q.type !== type);

                    if (count === -1) count = questionsLeft.length + 1;
                }

                return {
                    questionState: QuestionStates.SECTION_DIALOG,
                    count,
                    sectionNo: state.sectionNo + 1,
                };
            }),
            setQuestionDialog: () => set(
                { questionState: QuestionStates.QUESTION_DIALOG }
            ),
            prev: () => set((state) => ({
                currentQuestion: Math.max(state.currentQuestion - 1, 0),
                questionState: QuestionStates.WAITING,
            })),
            next: () => set((state) => ({
                currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length - 1),
                questionState: QuestionStates.DELAY,
            })),
            analysed: () => set({ questionState: QuestionStates.ANALYSED }),
            error: () => set({ questionState: QuestionStates.ERROR }),
            startTutorial: () => set({
                questionState: QuestionStates.SHOWING_TUTORIAL,
                assessmentState: AssessmentStates.SHOWING_TUTORIAL,
            }),
            endTutorial: () => set({
                questionState: QuestionStates.WAITING,
                assessmentState: AssessmentStates.FETCHING_QUESTIONS,
            }),
            retake: () => set({ questionState: QuestionStates.RETAKE }),
            start: () => set({ questionState: QuestionStates.RECORDING }),
            submit: () => set({
                questionState: QuestionStates.SUBMITTED,
                uploadProgress: 0,
            }),
            endAssessment: () => {
                window?.parent?.postMessage("ASSESSMENT_SUBMITTED", "*");
                set({
                    isTimeout: true,
                    assessmentState: AssessmentStates.WAITING_FOR_RESULT,
                });
            },
            startAssessment: () => set((state) => {
                const bufferTimeInSeconds = (state.template?.bufferTime || 0) * 60; 
                const questionsTotalTime = state.questions.reduce(
                    (acc, curr) => acc + curr.allottedTime, 0
                );

                return {
                    totalTime: questionsTotalTime + bufferTimeInSeconds,
                    timeLeft: questionsTotalTime + bufferTimeInSeconds,
                    currentQuestion: 0,
                    sectionNo: 0,
                    assessmentState: AssessmentStates.RUNNING,
                    questionState: QuestionStates.WAITING,
                    isTimeout: false,
                    questionResults: state.questions.map(() => ({
                        submitted: false,
                        recorded: false,
                        analysisRecieved: false,
                        analysisData: null,
                    })),
                };
            }),
            restartAssessment: () => set((state) => {
                const bufferTimeInSeconds = (state.template?.bufferTime || 0) * 60; 
                const questionsTotalTime = state.questions.reduce(
                    (acc, curr) => acc + curr.allottedTime, 0
                );

                return {
                    totalTime: questionsTotalTime + bufferTimeInSeconds,
                    timeLeft: questionsTotalTime + bufferTimeInSeconds,
                    currentQuestion: 0,
                    sectionNo: 0,
                    assessmentState: AssessmentStates.LOADING,
                    questionState: QuestionStates.WAITING,
                    setRetake: RETAKES_AVAILABLE,
                    isTimeout: false,
                    questionResults: state.questions.map(() => ({
                        submitted: false,
                        recorded: false,
                        analysisRecieved: false,
                        analysisData: null,
                    })),
                };
            }),
            onAnalysisEvent: (event, data) => {
                if (event === "progress") {
                    const progress = Number(data);
                    if (progress < 100) set({ uploadProgress: progress });
                    else set({ questionState: QuestionStates.ANALYSING });
                } else if (event === "error") {
                    get().error();
                } else if (event === "analysed") {
                    get().analysed();
                }
            },
            reset: () => set(defaults),
        }),
        {
            name: "sep-test",
            getStorage: () => sessionStorage,
        }
    )
);

export default useStore;


