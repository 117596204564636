import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import shallow from "zustand/shallow";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CenterFlexBox from "components/CenterFlexBox";
import CustomButton from "components/CustomButton";
import { useLoading, useSnackbar } from "contexts";
import { useNavigateWithClient } from "hooks";
import { signOut, verifyToken, getAttemptStatus, getTemplateQuestionsById, getTemplateId } from "services";
import useStore from "../store";
import { Session } from "utils";
import AppShell from "components/AppShell";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 560,
        width: "80vw",
        padding: 32,
        borderRadius: 8,
    },
}));

export default function Home() {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const loading = useLoading();
    const snackbar = useSnackbar();
    const navigateWithClient = useNavigateWithClient();

    const [setAttempt, setQuestions, setTemplate] = useStore(state => [
        state.setAttempt, state.setQuestions, state.setTemplate
    ], shallow);

    const template = useStore(state => state.template, shallow);

    const [verified, setVerified] = useState(false);
    const [message, setMessage] = useState("Loading Assessment...");

    const handleNext = () => navigateWithClient("/pre-assessment");

    useEffect(() => {
        (async () => {
            try {
                loading.show();

                const token = searchParams.get("at");

                if (!token) {
                    signOut();
                    throw new Error("TOKEN_NOT_PROVIDED");
                }

                const { attemptId } = await verifyToken(token);

                if (!attemptId) {
                    setVerified(false);
                    signOut();
                    throw new Error("INVALID_TOKEN");
                }
                setAttempt(attemptId);

                const { templateId, finished } = await getAttemptStatus(attemptId);

                if (finished) {
                    navigateWithClient("/link-expired?r=ATTEMPT_FINISHED");

                    setVerified(false);
                } else {
                    const template = await getTemplateId(templateId);
                    setTemplate(template);
                    
                    const { questions } = await getTemplateQuestionsById(templateId);
                    
                    const typeOrder = ["spoken", "written", "scq", "mcq"];

                    const sortedQuestions = questions.sort((a, b) => {
                        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
                    });
                    
                    setQuestions(sortedQuestions);
                    setVerified(true);

                    snackbar.showSnackbar("Authenticated Successfully!!", "success");
                }
            } catch (error) {
                console.error("AUTHENTICATION_ERROR::", error);
                setMessage("Something went wrong! Unable to verify you.");
                navigateWithClient(`/link-expired?r=${error.message}`);
            } finally {
                loading.hide();
                snackbar.hideSnackbar();
            }
        })();
    }, []);

    const handleTermsAndConditionClick = () => {
        window.open("https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf", "_blank");
    };

    return (
        <AppShell>
            <Box className={classes.container} >
                <img
                    width={230}
                    src={Session?.client?.metadata?.logoUrl}
                    alt={Session?.client?.name || "languify"}
                />

                <Typography
                    variant="fontFamily"
                    fontWeight="700"
                    fontSize="24px"
                    textAlign="center"
                    lineHeight="24px"
                    mt={5}
                    mb={2}
                    dangerouslySetInnerHTML={{
                        __html: template?.name || ""
                    }}
                />
                <Typography
                    variant="fontFamily"
                    fontWeight="700"
                    fontSize="18px"
                    textAlign="center"
                    lineHeight="24px"
                    mt={1}
                    mb={2}
                    dangerouslySetInnerHTML={{
                        __html: template?.description || ""
                    }}
                />

                {verified ? (
                    <CustomButton
                        sx={{ alignSelf: "center", marginTop: 5, px: 5, py: '2px' }}
                        size="small"
                        onClick={handleNext}
                    >
                        Next
                    </CustomButton>
                ) : (
                    <Typography fontSize={14} mt={4} color="primary" textAlign='center'>
                        {message}
                    </Typography>
                )}

                <CenterFlexBox display="flex" mt={2}>
                    <Typography
                        variant="caption"
                        fontSize={12}
                        fontWeight="500"
                        textAlign={"center"}
                        color="#535353"
                    >
                        By clicking on Next, you agree to our &nbsp;
                        <Typography
                            variant="caption"
                            fontSize={12}
                            component="a"
                            fontWeight="bold"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={handleTermsAndConditionClick}
                        >
                            terms and conditions
                        </Typography>
                    </Typography>
                </CenterFlexBox>
            </Box>
        </AppShell>
    );
}